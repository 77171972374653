import { useNavigate } from "react-router";
import { useAuth } from "../../auth/contexts/AuthProvider";
import { useSnackbar } from "../../core/contexts/SnackbarProvider";

export default async function sessionInterceptor(response: Response) {
  if (response.status === 401) {
    console.warn("Sessão expirada");
    //console.log(response.status);

    window.location.replace("/login");
    // destruir sessão do usuario
    return;
  }
}
