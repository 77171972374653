import Box, { BoxProps } from "@material-ui/core/Box";
import { ReactComponent as LogoSvg } from "../assets/logo.svg";

import LogoAgnes from "../assets/logo-agnes-illustrator.png";

type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ colored = false, size = 40, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <img src={LogoAgnes} alt="Logo" style={{ width: "210px" }} />
    </Box>
  );
};

export default Logo;
