import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import SvgContainer from "./SvgContainer";

import ProcessImg from "../../core/assets/process.png";
import Close from "@material-ui/icons/Close";
type ConfirmDialogProps = {
  description?: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  pending: boolean;
  title: string;
};

const ModalChat = ({
  description,
  onClose,
  onConfirm,
  open,
  pending,
  title,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      style={{
        zIndex: 999999,
      }}
      open={open}
      onClose={onClose}
      fullScreen
      className="chatDialogLeft"
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogActions sx={{ m: 0, p: 1 }}>
        {/* <Button onClick={onClose}>{t("common.cancel")}</Button> */}
        <LoadingButton
          autoFocus
          //@ts-ignore
          color="error"
          size="small"
          onClick={onConfirm}
          loading={pending}
          variant="contained"
          style={{ height: "40px" }}
          startIcon={<Close />}
        >
          Fechar Chat
        </LoadingButton>
      </DialogActions>
      <DialogContent
        sx={{ textAlign: "center", m: 0, p: 1, overflow: "hidden" }}
      >
        <iframe
          className="iframeArea"
          src="https://main.d1xvbis48sm8yx.amplifyapp.com/"
        ></iframe>
      </DialogContent>
    </Dialog>
  );
};

export default ModalChat;
