import { useState } from "react";
import ModalChat from "./core/components/ModalChat";
import "./core/css/chat.css";
import { Tooltip } from "@mui/material";
function Chat() {
  const [modalChat, setModalChat] = useState(false);
  return (
    <>
      <ModalChat
        description="Verifique, alguns campos não estão preenchidos corretamente!"
        onClose={() => setModalChat(false)}
        onConfirm={() => setModalChat(false)}
        open={modalChat}
        pending={false}
        title="Campos com erros!"
      />
      <div className="floating-container">
        <Tooltip title="Atendimento Agnes IA" disableInteractive>
          <div
            className="floating-button"
            onClick={() => setModalChat(true)}
          ></div>
        </Tooltip>
      </div>
    </>
  );
}

export default Chat;
