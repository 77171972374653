import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../../core/hooks/useLocalStorage";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";

interface AuthContextInterface {
  hasRole: (roles?: string[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  create: (
    cpf: string,
    email: string,
    password: string,
    verifyPassword: string
  ) => Promise<any>;
  verify: (cpf: string) => Promise<any>;
  logout: () => Promise<any>;
  userInfo?: UserInfo;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authKey, setAuthKey] = useLocalStorage<any>("authkey", "");

  const { isLoggingIn, login, verifyCandidate, create } = useLogin();
  const { isLoggingOut, logout } = useLogout();
  const { data: userInfo } = useUserInfo(authKey);

  const hasRole = (roles?: string[]) => {
    if (!roles || roles.length === 0) {
      return true;
    }
    if (!userInfo) {
      return false;
    }
    return roles.includes(userInfo.role);
  };

  const handleVerify = async (cpf: string) => {
    return verifyCandidate({ cpf })
      .then((result: any | boolean) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleLogin = async (cpf: string, password: string) => {
    return login({ cpf, password })
      .then((key: string | boolean) => {
        setAuthKey(key);
        if (key === false) {
          return false;
        }
        return key;
      })
      .catch((err) => {
        return false;
      });
  };

  const handleCreate = async (
    cpf: string,
    email: string,
    password: string,
    verifyPassword: string
  ) => {
    return create({ cpf, password, email })
      .then((key: string | boolean) => {
        setAuthKey(key);
        if (key === false) {
          return false;
        }
        return key;
      })
      .catch((err) => {
        return false;
      });
  };

  const handleLogout = async () => {
    return logout()
      .then((data) => {
        setAuthKey("");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        verify: handleVerify,
        create: handleCreate,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
