import axios from "axios";
import { useMutation } from "react-query";
import api from "../../admin/infra/api";

const login = async ({
  cpf,
  password,
}: {
  cpf: string;
  password: string;
}): Promise<string | boolean> => {
  const updateEndpoint = process.env.REACT_APP_API_URL + `/session/`;
  try {
    const response = await api(updateEndpoint, {
      method: "POST",
      body: JSON.stringify({
        cpf: cpf,
        senha: password,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();
    localStorage.setItem("rh-user", data.pessoal_nome);
    localStorage.setItem("rh-userId", data.pessoal_codigo);
    localStorage.setItem("rh-ativo", data.cad_finalizado);
    localStorage.setItem("rh-token", data.token);
    localStorage.setItem("rh-refreshToken", data.refresh_token);

    localStorage.setItem("rh-pessoalBairro", data.pessoal_bairro);
    localStorage.setItem("rh-cidade", data.pessoal_cidade);
    localStorage.setItem("rh-pessoalUf", data.pessoal_uf);
    localStorage.setItem("rh-pessoalEntrevista", data.pessoal_entrevista);
    localStorage.setItem("rh-idade", data.idade);
    localStorage.setItem("rh-email", data.pessoal_email);

    return "AUTHKEY123";
  } catch (err) {
    console.log(err);
    return false;
  }
};

const create = async ({
  cpf,
  email,
  password,
}: {
  cpf: string;
  email: string;
  password: string;
}): Promise<string | boolean> => {
  const updateEndpoint = process.env.REACT_APP_API_URL + `/auth/candidate`;
  try {
    const response = await api(updateEndpoint, {
      method: "PUT",
      body: JSON.stringify({
        cpf: cpf,
        email: email,
        senha: password,
      }),
      mode: "cors",
      headers: {
        "content-Type": "application/json",
      },
    });

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();
    localStorage.setItem("rh-user", data.pessoal_nome);
    localStorage.setItem("rh-userId", data.pessoal_codigo);
    localStorage.setItem("rh-ativo", data.cad_finalizado);

    return "AUTHKEY123";
  } catch (err) {
    console.log(err);
    return false;
  }
};

const verifyCandidate = async ({
  cpf,
}: {
  cpf: string;
}): Promise<any | boolean> => {
  const updateEndpoint =
    process.env.REACT_APP_API_URL + `/auth/candidate?cpf=${cpf}`;
  try {
    const response = await api(updateEndpoint);

    if (response.status >= 203) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return {
    isLoggingIn: isLoading,
    login: mutateAsync,
    verifyCandidate: verifyCandidate,
    create: create,
  };
}
